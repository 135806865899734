import { ApiEndpoints } from '../enums';
import { VoyadoCartItem } from '../models';
import UrlService from './url-service';
import Utilities from './utilities';
import CartService from './cart-service';

/**
 * The Voyado Service
 */
class VoyadoService {
  static ErrorReason: string;

  /**
   * Add Voyado Contact subscriber via api
   * @param email - user email adress
   * @param country - user country
   * @param storeId - audience/list
   * @param source - Signup source
   */
  /* eslint-disable */
  static async addVoyadoContactAsync(email: string, country: string, storeId: string, source: string): Promise<any> {
    const requestOptions = {
      method: 'POST',
    };

    // if not set, get store id from global Printler object
    if (storeId.length == 0) {
      storeId = window.Printler.StoreId;
    }

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.VoyadoAddContact, {
      email: email,
      country: country,
      storeId: storeId,
      source: source,
    });

    const response = await fetch(requestUrl, requestOptions);
    const data = await response.json();

    if (data == 'null' || data == null) {
      Utilities.log('Could not register. Already registered?');
      return { 'ok': false, 'success': false, 'ErrorMessage': 409 };
    }

    if (response.ok) {
      Utilities.log('Successfully registered email.');
      // set contact id if we have a value
      var jsonData = JSON.parse(data);
      if (jsonData?.id != '') {
        this.setVoyadoContactId(jsonData?.id);
      }
      return { 'ok': true, 'success': true };
    }

    return data;
  }
  /* eslint-enable */

  /**
   * Get Voyado contact json from email
   * @param email
   */
  static async getVoyadoContactAsync(email: string, contactType = 'Member'): Promise<object> {
    if (!email) {
      return;
    }

    const requestOptions = {
      method: 'GET',
    };

    const requestUrl = UrlService.getRenderUrl(ApiEndpoints.VoyadoGetContact, {
      email: email,
      contactType: contactType,
    });

    const response = await fetch(requestUrl, requestOptions);
    return await response.json();
  }

  /**
   * This method will set cookie “_vaI”. Invoke this method only when the user has been identified by the website, e.g. sign in. 
   * Be cautious, user might already be identified (e.g. by email link click), invoking this method incorrectly might clear existing identification.
   * @param contactId
   */
  static setVoyadoContactId(contactId: string): void {
    const contactCookie = Utilities.getCookie('_val');

    if (contactId == '') {
      return;
    }

    // if no contact has been set or if value undefined
    if (!contactCookie || contactCookie === undefined) {
      // no cookie set - we can set contact id
      window.va('setContactId', contactId);
    }
  }

  /**
   * Clear cart (after purchase)
   * @param cartId - Printler cart id
   */
  static emptyCart(cartId: string): void {
    if (cartId.length === 0) {
      return;
    }

    window.va('emptyCart', {
      'cartRef': `${cartId}`
    });
  }

  /**
   * Update cart - called after changes in cart
   * https://integrate.voyado.com/web-activity-tracking/track-shopping-cart-changes/
   */
  static updateCart(): void {
    const cartId: number = window.Printler.Cart;

    if (cartId === 0 || cartId === undefined) {
      return;
    }

    CartService.getCartItems().then(result => {
      setTimeout(() => {
        let items: VoyadoCartItem[] = [];

        result?.forEach((cartItem) => {
          const sku = cartItem.IsAddon ? `PRODUCT-${cartItem.Product}` : `${cartItem.Photo}`;
          // Only push items that are motifs for the abandoned cart automation to fire. 
          // Voyado can't handle product SKU's that does not exist in the product feed.
          if (!cartItem.IsAddon) {
            items.push({
              'itemId': sku,
              'quantity': 1,
            });
          }
        });

        // Reduce the items array and set quantity if more than one occurance
        items?.forEach(ci => {
          ci.quantity = VoyadoService.getCountInArray(items, ci.itemId);
        });

        items = items.filter((v, i, a) => a.findIndex(v2 => (v2.itemId === v.itemId)) === i);

        window.va('cart', {
          'cartRef': window.Printler.Cart, // must be unique and must not be shared between different baskets or users. 
          'locale': VoyadoService.getLocaleFromLangCode(window.Printler.Locale), // must be in this format sv-SE
          'items': items
        });

      }, 5000); // make sure cart updates to finish before fetching items
    });
  }

  /**
   * Get the full locale string from lang
   * @param langCode
   */
  static getLocaleFromLangCode(langCode: string): string {
    let locale = 'en-GB';

    switch (langCode) {
      case 'sv':
        locale = 'sv-SE'
        break;
      case 'no':
        locale = 'nb-NO'
        break;
      case 'da':
        locale = 'da-DK'
        break;
      case 'de':
        locale = 'de-DE'
        break;
      case 'it':
        locale = 'it-IT'
        break;
      case 'nl':
        locale = 'nl-NL'
        break;
      case 'fr':
        locale = 'fr-FR'
        break;
      case 'fi':
        locale = 'fi-FI'
        break;
      case 'pl':
        locale = 'pl-PL'
        break;
      case 'es':
        locale = 'es-ES'
        break;
      case 'pt':
        locale = 'pt-PT'
        break;
      case 'cs':
        locale = 'cs-CZ'
        break;
      case 'hu':
        locale = 'hu-HU'
        break;
      case 'sk':
        locale = 'sk-SK'
        break;
      case 'de-AT':
        locale = 'de-AT'
        break;
      default:
        locale = 'en-GB';
    }

    return locale;
  }

  /**
   * Counts the occurrences of a property in our cart items array
   * @param items
   * @param itemId
   */
  static getCountInArray(items: VoyadoCartItem[], itemId: string): number {
    return items.reduce((acc, cur) => cur.itemId === itemId ? ++acc : acc, 0);
  }
}

export default VoyadoService;